$color-white: #ffffff;
$color-black: #000000;
$color-grey-dark: #666666;
$color-grey: #969696;
$color-grey-medium: #d6d6d6;
$color-grey-light: #f5f5f5;
$color-pink: #fd717d;

$colors: (
    'black': $color-black,
    'grey': $color-grey,
    'grey-medium': $color-grey-medium,
    'grey-light': $color-grey-light,
    'white': $color-white,
    'pink': $color-pink
);

$navbar-height: 60px;
