@font-face {
    font-family: 'Silka';
    src: url('silka-regular-webfont.eot');
    src: url('silka-regular-webfont.eot?#iefix') format('embedded-opentype'), url('silka-regular-webfont.woff2') format('woff2'),
        url('silka-regular-webfont.woff') format('woff'), url('silka-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Silka';
    src: url('silka-bold-webfont.eot');
    src: url('silka-bold-webfont.eot?#iefix') format('embedded-opentype'), url('silka-bold-webfont.woff2') format('woff2'),
        url('silka-bold-webfont.woff') format('woff'), url('silka-bold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
