@import 'variables';

.button-unstyled {
    outline: none !important;
    border: none !important;
    background: none;
    padding: 0;

    &:focus {
        outline: none !important;
    }
}

.btn {
    border-width: 2px;
    border-radius: 0;
    padding: 16px 40px;
    transition: 0.1s;
}

.btn-white-border {
    background: none;
    color: $color-white;
    border: 2px solid $color-white;
}

.btn-black-border {
    background: none;
    border: 2px solid $color-black;

    &:hover,
    &:disabled {
        border: 2px solid rgba($color-black, 0.85);
        background: none;
    }
}

.btn-black {
    background-color: $color-black;
    color: $color-white;

    &:hover,
    &:disabled {
        color: $color-white;
        background-color: rgba($color-black, 0.85);
    }
}

.btn-fab-pink {
    border: none;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: $color-pink;
    color: $color-white;
    font-size: 30px;

    &:hover {
        color: $color-white;
        background-color: rgba($color-pink, 0.9);
    }
}
