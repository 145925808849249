@import 'scss/bootstrap';
@import 'scss/colors';
@import 'scss/typography';
@import 'scss/buttons';
@import 'scss/forms';
@import 'scss/aos';

*:focus {
    outline: 0;
}

/* Commons */

.filter-black {
    position: relative;
}

.filter-black:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
}

.content {
    position: relative;
    z-index: 1;
}

.icon-small {
    height: 20px;
}

.icon-medium {
    height: 30px;
}

.icon-big {
    height: 50px;
}

.icon-animated {
    animation: leaves 3.5s ease-in-out infinite alternate;
}

@keyframes leaves {
    0% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}

.line {
    border-top: 2px solid $color-black;
    opacity: 1;
}

.image-mask {
    mask-size: contain;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
}

.mask-01 {
    mask-image: url('/assets/images/image-mask-01.svg');
    -webkit-mask-image: url('/assets/images/image-mask-01.svg');
}

.mask-02 {
    mask-image: url('/assets/images/image-mask-02.svg');
    -webkit-mask-image: url('/assets/images/image-mask-02.svg');
}

.mask-03 {
    mask-image: url('/assets/images/image-mask-03.svg');
    -webkit-mask-image: url('/assets/images/image-mask-03.svg');
}

.mask-04 {
    mask-image: url('/assets/images/image-mask-04.svg');
    -webkit-mask-image: url('/assets/images/image-mask-04.svg');
}

.mask-05 {
    mask-image: url('/assets/images/image-mask-05.svg');
    -webkit-mask-image: url('/assets/images/image-mask-05.svg');
}

.Unterstrich {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -0.5rem;
        left: -0.5rem;
        right: -0.5rem;
        height: 100%;
        background-position: 0 100%;
        z-index: -1;
        background-image: url('/assets/images/underline.svg');
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.Weißer-Unterstrich {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -0.5rem;
        left: -0.5rem;
        right: -0.5rem;
        height: 100%;
        background-position: 0 100%;
        z-index: -1;
        background-image: url('/assets/images/underline-white.svg');
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.list-item-point {
    margin-top: 40px;
    padding: 10px 50px;
    list-style: none;
    background-image: url('/assets/images/checkmark.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 25px;
}

.list-item-plus {
    margin-top: 40px;
    padding: 10px 50px;
    list-style: none;
    background-image: url('/assets/images/plus-red.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 25px;
}

.nav-dropdown {
    &:active,
    &:hover,
    &:focus-within,
    &:focus-visible {
        background-color: $color-black;
        color: $white;
    }
}

.badge-outline {
    border: solid 2px $color-pink;
    font-weight: bold;
    border-radius: 5px;
    color: $color-pink;
    font-size: 12px;
    width: fit-content;
    padding: 4px 20px;
    text-transform: uppercase;

    &.tiny {
        font-size: 10px;
        padding: 4px 15px;
    }
}
.hydration-in-progress [data-aos] {
    opacity: 0; /* Unsichtbar vor der Hydration */
    visibility: hidden; /* Verhindert plötzliches Blinken */
    transition: opacity 0.5s ease, visibility 0.5s ease;
}

.hydration-complete [data-aos] {
    opacity: 1; /* Sichtbar nach der Hydration */
    visibility: visible;
}
